import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmCancelDialogComponent } from 'src/app/account/confirm-cancel-dialog/confirm-dialog.component';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { FeaturesTableComponent } from '../../account/features-table/features-table.component';
import { MatDivider } from '@angular/material/divider';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatButton } from '@angular/material/button';
import { DecimalPipe, TitleCasePipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-payment-information',
    templateUrl: './payment-information.component.html',
    styleUrls: ['./payment-information.component.scss'],
    standalone: true,
    imports: [
    MatButton,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatDivider,
    FeaturesTableComponent,
    DecimalPipe,
    TitleCasePipe,
    DatePipe
],
})
export class PaymentInformationComponent implements OnInit {
  @Input() pricingExpanded = true;
  @Input() featuresExpanded = true;
  @Input() showChangePlan = true;
  @Input() showCancelSubscription = true;
  @Input() order;
  processedOrder;
  
  loading = true;
  isAgency = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private utilities: UtilitiesService
  ) {}

  ngOnInit() {
    this.isAgency = this.utilities.isAgencyAccount;

    if (this.order) {
      // Get Order
      const {
        _id,
        date,
        status,
        product,
        transaction_info: {
          total,
          price,
          subscription,
          payment,
          mode,
          donation,
        },
        subscription_canceled: subscriptionCanceled,
        agency_name,
        owned,
        profile_id
      } = this.order;

      // Features
      const allFeatures = [];
      for (const enabledFeature of product.enabled_features) {
        enabledFeature.hasAccess = true;
        allFeatures.push(enabledFeature);
      }
      for (const disabledFeature of product.disabled_features) {
        disabledFeature.hasAccess = false;
        allFeatures.push(disabledFeature);
      }
      product.all_features = allFeatures;

      // Showcase Items
      product.showcase_items = product.showcase_items.map((i) => ({
        ...i,
        hasAccess: true,
        enabled: true,
      }));

      // Default Price
      const defaultPrice = product.prices.filter(
        (productPrice) => productPrice.is_default
      )[0];
      if (defaultPrice.type === 'recurring') {
        defaultPrice.recurring_text = `${
          defaultPrice.recurring.interval_count > 1
            ? defaultPrice.recurring.interval_count + ' '
            : ''
        }${
          defaultPrice.recurring.interval_count > 1
            ? defaultPrice.recurring.interval + 's'
            : defaultPrice.recurring.interval
        }`;
      }
      product.default_price = defaultPrice;

      // Current Price
      if (price.type === 'recurring') {
        price.recurring_text = `${
          price.recurring.interval_count > 1
            ? price.recurring.interval_count + ' '
            : ''
        }${
          price.recurring.interval_count > 1
            ? price.recurring.interval + 's'
            : price.recurring.interval
        }`;
      }

      // Trial End
      if (subscription) {
        const end = new Date(subscription.trial_end * 1000);
        const now = new Date();
        subscription.trial_days_remaining = Math.ceil(
          Math.abs(end.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
        );

        const last_charge = new Date(subscription.current_period_start * 1000);
        const next_charge = new Date(subscription.current_period_end * 1000);

        subscription.last_charge = last_charge.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        subscription.next_charge = next_charge.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
      }

      // Discount
      const coupon =
        subscription?.discount?.coupon || payment?.discount?.coupon;
      if (coupon) {
        const amountOff = coupon.amount_off ? coupon.amount_off : 0;
        const percentOff = coupon.percent_off ? coupon.percent_off : 0;
        const couponDuration = coupon.duration;
        const couponMonthDuration = coupon.duration_in_months;

        // Get months in interval
        let numDiscountedPayments = 0;
        if (price.recurring) {
          const intervalMonths =
            price.recurring.interval === 'year'
              ? price.recurring.interval_count * 12
              : price.recurring.interval_count;
          numDiscountedPayments = Math.ceil(
            couponMonthDuration / intervalMonths
          );
        }

        const discountText = `${amountOff ? '$' + amountOff / 100 : ''}${
          percentOff ? percentOff + '%' : ''
        }`;

        const onceText = `the first ${price.recurring_text}`;
        const foreverText = `every ${price.recurring_text}`;
        const repeatingText = `for the first ${numDiscountedPayments} payments`;

        const repeatingDurationText =
          couponDuration === 'once'
            ? onceText
            : couponDuration === 'forever'
            ? foreverText
            : couponDuration === 'repeating'
            ? repeatingText
            : '';

        subscription.discount.text = `${discountText} off ${
          price.type === 'recurring' ? repeatingDurationText : ''
        }`;
      }

      const couponAdjustedPrice = (amount, chargeDate) => {
        if (subscription?.discount?.coupon && chargeDate) {
          if (
            chargeDate >= subscription.discount.start &&
            (!subscription.discount.end ||
              chargeDate <= subscription.discount.end)
          ) {
            if (subscription.discount.coupon.amount_off) {
              return amount - subscription.discount.coupon.amount_off;
            } else if (subscription.discount.coupon.percent_off) {
              return (
                amount * (1 - subscription.discount.coupon.percent_off / 100)
              );
            }
          }
        }
        return amount;
      };

      // Status - Active, Trial or Expiring
      let planStatus = null;
      if (subscriptionCanceled) {
        planStatus = 'expiring';
      } else if (
        !(price.type === 'one_time' && price.metadata.trial_period_days) &&
        subscription?.status === 'trialing'
      ) {
        planStatus = 'trial';
      } else if (status === 'paid') {
        planStatus = 'active';
      }

      // Future Charges
      let nextCharge = null;
      if (subscriptionCanceled) {
        nextCharge = 0;
      } else if (
        price.type === 'one_time' &&
        price.metadata.trial_period_days
      ) {
        nextCharge = couponAdjustedPrice(
          product.default_price.unit_amount,
          subscription?.current_period_end
        );
      } else {
        nextCharge = couponAdjustedPrice(
          price.unit_amount,
          subscription?.current_period_end
        );
      }

      // Past Charges
      let pastCharge = null;
      if (
        price.type === 'one_time' &&
        price.metadata.trial_period_days &&
        subscription?.status !== 'trialing'
      ) {
        pastCharge = couponAdjustedPrice(
          product.default_price.unit_amount,
          subscription?.current_period_start
        );
      } else if (
        price.type === 'one_time' &&
        price.metadata.trial_period_days &&
        subscription?.status === 'trialing'
      ) {
        pastCharge = couponAdjustedPrice(
          price.unit_amount,
          subscription?.current_period_start
        );
      } else if (subscription?.status === 'trialing') {
        pastCharge = 0;
      } else {
        pastCharge = couponAdjustedPrice(
          price.unit_amount,
          subscription?.current_period_start
        );
      }

      // Donation
      const localData = localStorage.getItem('kickStart');
      let donationAmount = null;
      let donationOrg = null;
      if (donation) {
        donationAmount = donation.unit_amount;
        donationOrg = donation.organization;
      } else if (localData) {
        const data = JSON.parse(localData);
        donationAmount = price.unit_amount * 0.1;
        donationOrg = data.organization;
      }

      this.processedOrder = {
        _id,
        product,
        price,
        subscription,
        status,
        mode,
        owned,
        subscriptionCanceled,
        startDate: new Date(date).toISOString().slice(0, 10),
        showMore: false,
        nextCharge,
        pastCharge,
        planStatus,
        donationAmount,
        donationOrg,
        agencyName: agency_name,
        showPayment: owned && profile_id === this.utilities.profileId
      };
    }

    this.loading = false;
  }

  navigate(route) {
    switch (route) {
      case 'change-plan': {
        this.router.navigateByUrl(`/account/change-plan`);
        break;
      }

      default: {
        this.router.navigateByUrl('/');
      }
    }
  }

  cancelMembership(orderId) {
    console.log(orderId);
    const data = {
      orderId,
    };
    const matDialog = this.dialog.open(ConfirmCancelDialogComponent, {
      panelClass: 'custom-dialog',
      data,
    });
  }
}
