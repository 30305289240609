/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  firstValueFrom,
  Observable,
  of,
} from 'rxjs';
import { api } from '../../config/apiUrls';
import { UtilitiesService } from './utilities.service';
import { FeatureLockModalComponent } from '../helper/feature-lock-modal/feature-lock-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageService } from './page.service';
import { environment } from 'src/environments/environment';
import { AccountService } from './account.service';
import { JWTTokenService } from './jwttoken.service';

export const PAGETYPE_TO_PAGE_EXT = {
  profiles: 'bio',
  bio: 'bio',
  passport: 'me',
  about: 'about',
  portfolio: 'creator',
  agency: 'agency',
};

export const PAGE_EXT_TO_PAGETYPE = {
  bio: 'bio',
  me: 'passport',
  about: 'about',
  creator: 'portfolio',
  agency: 'agency',
};

@Injectable({
  providedIn: 'root',
})
export class ProfilesService {
  constructor(
    private http: HttpClient,
    private utilities: UtilitiesService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private pageService: PageService,
    private accountService: AccountService,
    private jwt: JWTTokenService
  ) {}

  listProfiles(options) {
    const { filters, ...rest } = options;
    const params = {
      ...rest,
      ...(filters &&
        filters.reduce(
          (obj, item) =>
            Object.assign(obj, {
              [item.split('=')[0]]: item.split('=')[1],
            }),
          {}
        )),
    };

    // default minimized fields
    if (!params['expanded_fields'] && params['minimized_fields'] !== 'all') {
      const currentMinimizedFields = params['minimized_fields']
        ? params['minimized_fields'].split(',')
        : [];
      params['minimized_fields'] = [
        ...currentMinimizedFields,
        'products_offered_in',
        'customization_options',
      ].join(',');
    }

    return this.http.get<any>(api.profiles, {
      params,
    });
  }

  async downloadProfileListToCSV(params = {}) {
    const options: Object = {
      params,
      headers: {
        'x-response-format': 'csv',
      },
      responseType: 'text',
    };
    const response: string = await firstValueFrom(
      this.http.get<any>(`${api.profiles}?exclude_id_column=true`, options)
    );

    if (response && typeof response === 'string') {
      const formatHeader = response.slice(0, response.indexOf(',') + 1);
      const data = response.slice(response.indexOf(',') + 1);
      const uri = formatHeader + encodeURIComponent(data);

      var link = document.createElement('a');

      link.href = uri;
      link.download = 'SV Managed Accounts';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  createProfile(body) {
    // default minimized fields
    // TODO: analyze what is needed on a case-by-case basis and pass in with options object instead.
    const params = {
      minimized_fields: 'products_offered_in,customization_options',
    };

    return this.http.post<any>(api.profiles, body, { params });
  }

  async retrieveProfile(
    profileId = null,
    params = {},
    useCache = true,
    updateCache = true
  ): Promise<any> {
    profileId = profileId || this.utilities?.profileId;

    // default minimized fields
    if (!params['expanded_fields'] && params['minimized_fields'] !== 'all') {
      const currentMinimizedFields = params['minimized_fields']
        ? params['minimized_fields'].split(',')
        : [];
      params['minimized_fields'] = [
        ...currentMinimizedFields,
        'products_offered_in',
        'customization_options',
      ].join(',');
    }

    let profile;
    let account;

    // Get stored profile from utilities
    if (useCache && profileId === this.utilities?.profileId) {
      profile = this.utilities.profileDetails;
    }

    if (!profile || !profile._id) {
      // Load Account if needed to get the profile id
      if (!profileId) {
        console.log('Getting account');
        const accountResponse = await firstValueFrom(
          this.accountService.retrieveAccount(this.jwt.getSub())
        );

        account = accountResponse;
        profileId = accountResponse.main_profile_id;
      }

      // Load profile
      console.log('getting profile');
      const profileResponse = await firstValueFrom(
        this.http.get<any>(`${api.profiles}/${profileId}`, { params })
      );

      profile = profileResponse;
    }

    console.log(profile);
    console.log(account);

    // Update utilities
    if (updateCache) {
      console.log('updating cache');
      if (profile) {
        this.utilities.profile = profile;
      }
      if (account) {
        this.utilities.account = account;
      }
    }

    return profile;
  }

  async listAgencyFeatured(): Promise<any> {
    // profileId = profileId || this.utilities?.profileId;
    // const useUtilities = !profileId || profileId === this.utilities?.profileId;

    // default minimized fields
    const params = {
      minimized_fields: 'products_offered_in,customization_options',
      status__is_disabled: false,
      status__is_archived: false,
    };

    return await firstValueFrom(
      this.http.get<any>(`${api.agencyFeatured}`, { params })
    );
  }

  // retrieveProfileQrCode(profileId, params) {
  //   params['host'] = api.shortUrl;
  //   params['color'] = params['color'].replace('#', '');

  //   return this.http.get(`${api.profiles}/${profileId}/qr`, {
  //     params,
  //     responseType: 'blob',
  //   });
  // }

  updateProfile(profileId, body, code = null, params = {}) {
    let profileResponse;

    // default minimized fields
    if (!params['expanded_fields'] && params['minimized_fields'] !== 'all') {
      const currentMinimizedFields = params['minimized_fields']
        ? params['minimized_fields'].split(',')
        : [];
      params['minimized_fields'] = [
        ...currentMinimizedFields,
        'products_offered_in',
        'customization_options',
      ].join(',');
    }

    if (code) {
      profileResponse = this.http.patch(
        `${api.profiles}/${profileId}?redeem=${code}`,
        body,
        { params }
      );
    } else {
      profileResponse = this.http.patch(`${api.profiles}/${profileId}`, body, {
        params,
      });
    }

    if (profileResponse) {
      // this.utilities.profileDetails = profileResponse; commenting this out as it is assigning observable
    }

    return profileResponse;
  }

  /**
   * Accepts a profile object in the same format as it is returned from the API,
   * and updates the profile using the API.
   * @param profile The full profile including any changes
   * @param photo An optional photo file for updating the profile picture
   * @returns the API response from update profile
   */
  // async updateFullProfile(profile_data, photo = null, identityPhoto = null) {
  //   const profile = { ...profile_data };

  //   // Extract Profile Id
  //   const profileId = profile._id;
  //   delete profile['_id'];
  //   if (!profileId) {
  //     return null;
  //   }

  //   // Update Profile Pic
  //   if (photo) {
  //     const upload_response = await this.uploadProfilePicture(profileId, photo);
  //     if (upload_response) {
  //       profile.image_index = upload_response.index;
  //       profile.image_ext = upload_response.ext;
  //     }
  //   }

  //   // Update Identity Profile Pic
  //   if (identityPhoto) {
  //     console.log("identity photo is not null")
  //     const upload_response = await this.uploadProfilePicture(profileId, identityPhoto, true);
  //     if (upload_response) {
  //       profile.identity_image_index = upload_response.index;
  //       profile.identity_image_ext = upload_response.ext;
  //     }
  //   }

  //   // Remove Read-only fields
  //   [
  //     'analytics',
  //     'badge_checks_required',
  //     'badges',
  //     'copy_number',
  //     'created_at',
  //     'features',
  //     'is_bio',
  //     'short_link',
  //     'standard_profiles',
  //     'updated_at',
  //     'username_history',
  //     'videos',
  //     'unverified_email',
  //     'email_verification_code',
  //     'email_verified',
  //     'owned',
  //   ].forEach((field) => delete profile[field]);

  //   // Map sub-objects to just IDs
  //   if (profile.active_theme && profile.active_theme._id) {
  //     profile.active_theme = profile.active_theme._id;
  //   }
  //   if (profile.referral_banner && profile.referral_banner._id) {
  //     profile.referral_banner = profile.referral_banner._id;
  //   }
  //   if (profile.links) {
  //     let linkArray = [];
  //     for (const link of profile.links) {
  //       if ('verified' in link) {
  //         delete link['verified'];
  //       }
  //       if (link._id) {
  //         linkArray.push(link._id);
  //       } else if (link) {
  //         linkArray.push(link);
  //       }
  //     }
  //   }

  //   // Update profile using body
  //   const response = await firstValueFrom(
  //     this.updateProfile(profileId, profile)
  //   );

  //   return response;
  // }

  /**
   * Accepts a profile object in the same format as it is returned from the API,
   * and updates the profile using the API.
   * @param profile The full profile including any changes
   * @param photo An optional photo file for updating the profile picture
   * @returns the API response from update profile
   */
  // async createFullProfile(profile_data, photo = null, identityPhoto = null) {
  //   const profile = { ...profile_data };

  //   // Extract Profile Id
  //   if (profile._id) {
  //     delete profile['_id'];
  //   }

  //   // Map sub-objects to just IDs
  //   if (profile.active_theme && profile.active_theme._id) {
  //     profile.active_theme = profile.active_theme._id;
  //   }
  //   if (profile.referral_banner && profile.referral_banner._id) {
  //     profile.referral_banner = profile.referral_banner._id;
  //   }
  //   if (profile.links) {
  //     let linkArray = [];
  //     for (const link of profile.links) {
  //       if ('verified' in link) {
  //         delete link['verified'];
  //       }
  //       if (link._id) {
  //         linkArray.push(link._id);
  //       } else if (link) {
  //         linkArray.push(link);
  //       }
  //     }
  //   }

  //   // Remove Read-only fields
  //   [
  //     'analytics',
  //     'badge_checks_required',
  //     'badges',
  //     'copy_number',
  //     'created_at',
  //     'features',
  //     'is_bio',
  //     'short_link',
  //     'standard_profiles',
  //     'updated_at',
  //     'username',
  //     'username_history',
  //     'videos',
  //     'unverified_email',
  //     'email_verification_code',
  //     'email_verified',
  //     'owned',
  //   ].forEach((field) => delete profile[field]);

  //   // Create profile using body
  //   const response = await firstValueFrom(this.createProfile(profile).pipe(
  //     catchError(
  //       (err) => of(err)
  //     )
  //   ));

  //   if (!response.error) {
  //     // Update Profile Pic
  //     return this.updateFullProfile(response, photo, identityPhoto);
  //   }

  //   return response;
  // }

  imageUploadUrl(profileId, body) {
    return this.http.post(`${api.profile}/${profileId}/image`, body);
  }

  // aboutImageUploadUrl(profileId, body) {
  //   return this.http.post(`${api.profiles}/${profileId}/image/identity`, body);
  // }

  imageUpload(url, formData) {
    return this.http.post(url, formData);
  }

  async uploadProfilePicture(profileId, newPhoto) {
    const res = await fetch(newPhoto);
    const ext = res.headers.get('Content-Type');
    const file = await res.blob();
    // you have raw data and content-type

    // Get presigned image upload URL
    let presignedResponse: any;
    presignedResponse = await firstValueFrom(
      this.imageUploadUrl(profileId, {
        extension: ext,
      })
    );

    const {
      presigned_url_data: {
        url,
        fields: {
          AWSAccessKeyId,
          key,
          policy,
          signature,
          // 'x-amz-security-token': token,
        },
      },
      image_doc,
    } = presignedResponse;

    // const {
    //   presigned_url_data: {
    //     url,
    //     fields: {
    //       AWSAccessKeyId,
    //       key,
    //       policy,
    //       signature,
    //       'x-amz-security-token': token,
    //     },
    //   },
    //   s3_location,
    // } = presignedResponse;

    // Format formdata
    const formData: FormData = new FormData();
    formData.append('AWSAccessKeyId', AWSAccessKeyId);
    formData.append('key', key);
    formData.append('policy', policy);
    formData.append('signature', signature);
    // formData.append('x-amz-security-token', token);
    formData.append('file', file);

    // Upload formdata to presigned URL
    await firstValueFrom(this.imageUpload(url, formData));

    // Update utilities profile if needed
    // if (profileId === this.utilities.profileId) {
    //   this.utilities.profile.image_index = image_doc.index;
    //   this.utilities.profile.image_ext = image_doc.extension;
    // }

    // Return file index and extension
    return {
      index: image_doc.index,
      ext: image_doc.extension,
    };
  }

  downloadProfilePicture(imageUrl) {
    return this.http.get(imageUrl, {
      responseType: 'blob',
    });
    //  .pipe(catchError(this.handleError('patchHero')));
  }

  downloadWatermarkPicture(watermark, image) {
    return this.http.post(
      api.framedImage,
      { watermark, image },
      {
        responseType: 'blob',
      }
    );
    //  .pipe(catchError(this.handleError('patchHero')));
  }

  // duplicateProfile(profileId, body = {}) {
  //   // const httpOptions = {
  //   //   headers: new HttpHeaders({
  //   //     'Content-Type': 'application/json',
  //   //     Authorization: 'my-auth-token',
  //   //   }),
  //   // };
  //   return this.http.post(`${api.profiles}/${profileId}/copy`, body).pipe(
  //     catchError(
  //       (err) => {
  //         this.snackbar.open(err.error.message, 'OK', {duration: 5000});
  //         throw new Error (err.error.message);
  //       }
  //     )
  //   );
  //   //  .pipe(catchError(this.handleError('patchHero')));
  // }

  //TODO check if this is needed
  checkProfileUsername(username, include_codes = true) {
    const body = { include_codes, username };
    return this.http.post(api.profilesAvailable, body);
  }

  async checkProfileUsernameAndPermissions(username, profileId) {
    if (username) {
      const usernameResponse = await firstValueFrom(
        this.checkProfileUsername(username)
      );
      const profileResponse = await this.retrieveProfile(profileId);
      const isCurrentUsername = profileResponse.username === username;
      const hasReservedAccess = await this.isFeatureEnabledForProfile(
        'premium-usernames',
        profileResponse
      );
      return {
        usernameStatus: usernameResponse,
        premiumAccess: hasReservedAccess,
        isCurrent: isCurrentUsername,
      };
    }
  }

  isFeatureEnabledForProfile(featureIdentifier, profile = null) {
    if (!profile) {
      profile = this.utilities.profile;
    }

    return (
      profile.features?.enabled_features.filter((feature) => {
        return feature.identifier === featureIdentifier;
      }).length > 0
    );
  }

  // async isFeatureEnabledForProfileId(profileId, featureIdentifier) {
  //   const res = await firstValueFrom(
  //     this.retrieveProfile(profileId).pipe(
  //       catchError((err, caught) => {
  //         return of({ error: true });
  //       })
  //     )
  //   );

  //   if (!res || res.error) {
  //     return false;
  //   }

  //   return this.isFeatureEnabledForProfile(res, featureIdentifier);
  // }

  async isFeatureEnabledForPage(page, featureIdentifier) {
    if (page.profile === this.utilities.profileId) {
      return this.isFeatureEnabledForProfile(featureIdentifier);
    } else {
      const profile = await this.retrieveProfile(page.profile, {
        expanded_fields: 'features',
      });
      return this.isFeatureEnabledForProfile(featureIdentifier, profile);
    }
  }

  deleteProfile(profileId) {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: 'my-auth-token',
    //   }),
    // };
    return this.http.delete(`${api.profiles}/${profileId}`);
  }

  listProfileLogs(profileId, options = undefined) {
    const params = {
      ...(options?.sort && { order_by: options.sort }),
      ...(options?.filters &&
        options.filters.reduce(
          (obj, item) =>
            Object.assign(obj, {
              [item.split('=')[0]]: item.split('=')[1],
            }),
          {}
        )),
    };

    return this.http.get<any>(`${api.profiles}/${profileId}/log`, { params });
  }

  createProfileLog(profileId, body) {
    return this.http.post<any>(`${api.profiles}/${profileId}/log`, body);
  }

  generateFramedImage(profileId, watermarkId, image = null) {
    const body = Object.assign(
      {},
      { watermark: watermarkId },
      image ? { image: image } : null
    );

    return this.http.post(`${api.profiles}/${profileId}/framed-image`, body, {
      responseType: 'blob',
    });
  }

  profileVerification(token) {
    return this.http.get<any>(
      `${api.verificationQuestionnaire}?token=${token}`
    );
  }

  profileVerificationPostResults(token, data) {
    return this.http.post<any>(
      `${api.verificationQuestionnaire}?token=${token}`,
      data,
      { observe: 'response' }
    );
  }

  getImageUrl(profile) {
    return profile.image_url;
  }

  getWatermarkUrl(watermark) {
    return `${api.watermarkImages}/${watermark._id}.${watermark.ext}`;
  }

  getVerifiedSocialBadgesForProfile(profileId) {
    return this.http.get<any>(
      `${api.profiles}/${profileId}/badges?type__in=linkedin,twitter,facebook,instagram,tiktok`
    );
  }

  getProfileIdentityBadge(profileId) {
    return this.http.get<any>(
      `${api.profiles}/${profileId}/badges?type=identity&is_active=true&minimized_fields=all`
    );
  }

  getAccountsForProfile(profileId) {
    return this.http.get<any>(`${api.profiles}/${profileId}/accounts`);
  }

  async getMainPageForProfile(
    profile = null,
    useCache = true,
    updateCache = true,
    minimized_fields = ''
  ) {
    if (!profile) {
      profile = this.utilities.profileDetails;
    }

    if (profile.preferred_page) {
      const page = await this.pageService.retrieveMainPage(
        profile.preferred_page,
        useCache,
        minimized_fields,
        updateCache
      );
      return page;
    } else {
      return undefined;
    }
  }

  async getUserMainPageLink(profile = null) {
    if (!profile) {
      profile = this.utilities.profileDetails;
    }

    // if (profile.username) {
    //   return profile.username;
    // } else {
    const mainPage = await this.getMainPageForProfile(profile);
    if (mainPage) {
      return mainPage.short_link;
    }
    // }
  }

  /**
   * For example: "chad" or "~hjs12"
   */
  async getPageUsernameOrShort(page, pageType, parentProfile = null) {
    // if (page.is_main) {
    //   if (!parentProfile) {
    //     if (this.utilities.profileId === page.profile) {
    //       parentProfile = this.utilities.profileDetails;
    //     } else {
    //       parentProfile = await this.retrieveProfile(
    //         page.profile,
    //         { minimized_fields: 'all' },
    //         true,
    //         false
    //       );
    //     }
    //   }

    //   const preferredPage = parentProfile.preferred_page;
    //   const username = parentProfile.username;

    //   if (username && preferredPage === pageType) {
    //     return username;
    //   } else {
    //     return page.short_link;
    //   }
    // } else {
      return page.short_link;
    // }
  }

  /**
   * For example: "https://socialvalid.com/chad/bio"
   */
  async getPageFullUrl(page, pageType, parentProfile = null) {
    // When in production, use the page's preferred domain if available.
    // Otherwise, fall back to the environment's clientUrl.
    const domain =
      environment.production && page.preferred_domain?.name
        ? page.preferred_domain.name
        : environment.clientUrl;

    // console.log(page.preferred_domain.name);

    // if (page.is_main) {
    //   if (!parentProfile) {
    //     if (this.utilities.profileId === page.profile) {
    //       parentProfile = this.utilities.profileDetails;
    //     } else {
    //       parentProfile = await this.retrieveProfile(
    //         page.profile,
    //         { minimized_fields: 'all' },
    //         true,
    //         false
    //       );
    //     }
    //   }

    //   const preferredPage = parentProfile.preferred_page;
    //   const username = parentProfile.username;

    //   if (username && preferredPage === pageType) {
    //     return `${domain}/${username}`;
    //   }
    // }

    // console.log(`${domain}/${page.short_link}`);

    return `${domain}/${page.short_link}`;
  }

  /* Username tracking for onboarding */
  private usernameSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public username: Observable<any> = this.usernameSubject.asObservable();

  updateUsername(profileId, username) {
    this.usernameSubject.next({
      profileId: profileId,
      username: username,
    });
  }
}
