/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '../../config/apiUrls';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

import * as mt from 'moment-timezone';
import { filter, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  uniqueCounter = 0;

  consoleLogs = [];

  urls = []; // LIFO Stack

  onboardingOpen = false;

  product = {
    _id: null,
    title: '',
    identifier: '',
  };

  account = {
    _id: null,
    accessible_links: [],
    cognito_subs: [],
    email: '',
    main_profile_id: '',
    full_name: '',
    description: '',
    location: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    zip_code: '',
    settings: {
      tz: '',
      notifications: [],
      expand_your_reach_agencies: false,
      expand_your_reach_brands: false,
    },
    flags: {
      show_portfolio_tour: true,
      show_portfolio_customize: true,
    },
    stripe_account_id: '',
    has_completed_stripe_onboarding: '',
    phone: '',
    new_account: false,
    onboarding_completed: false,
    onboarding_next_step: '',
    onboarding_mode: null,
    ambassador_status: '',
    dob: '',
    effective_permissions: [],
    permission_groups: [],
    deep_web_monitoring_enabled: false,
    deep_web_monitoring_accounts: [],
    spotlight_footer: '',
    referred_by_data: {
      referral_id: null,
    },
    profile_roles: [],
  };

  profile = {
    _id: null,
    dob: '',
    zip_code: '',
    about_sv_score: 0,
    bio_sv_score: 0,
    passport_sv_score: 0,
    badges: [],
    videos: [],
    features: {
      enabled_features: [],
      disabled_features: [],
    },
    email: '',
    email_verified: '',
    unverified_email: '',
    username: '',
    username_history: '',
    preferred_page: '',
    image_url: '',
    managing_accounts: [],
  };

  mainBio = {
    _id: null,
    short_link: null,
  };

  mainPassport = {
    _id: null,
    short_link: null,
  };

  mainAbout = {
    _id: null,
    short_link: null,
  };

  mainPortfolio = {
    _id: null,
    short_link: null,
  };

  features = {};
  currentPage = '';

  domains = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private browserLocation: Location
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentPage = event.url;
      });
  }

  set svDomains(domains) {
    if (domains) {
      this.domains = domains;
    }
  }

  get svDomains() {
    return this.domains;
  }

  set activeProduct(product) {
    if (product) {
      this.product = product;
    }
  }

  get activeProduct() {
    return this.product;
  }

  set accountDetails(account) {
    if (account) {
      this.account = account;
    }
  }

  get accountDetails() {
    return this.account;
  }

  get accessibleLinks() {
    return this.account.accessible_links;
  }

  get hasStripeAccountId() {
    return !(
      this.account.stripe_account_id === undefined ||
      this.account.stripe_account_id === null
    );
  }

  get hasCompletedStripeOnboarding() {
    return !!this.account.has_completed_stripe_onboarding;
  }

  get accountId() {
    return this.account._id;
  }

  set profileDetails(profile) {
    if (profile) {
      this.profile = profile;
    }
  }

  get consoleHistory() {
    return this.consoleLogs;
  }

  get profileDetails() {
    return this.profile;
  }

  get profileId() {
    return (
      localStorage.getItem('selectedProfileId') ||
      this.profile._id ||
      this.account.main_profile_id
    );
  }

  get email() {
    return this.account.email;
  }

  get accountFullName() {
    return `${this.account.first_name} ${this.account.last_name}` || '';
  }

  get username() {
    return this.profile.username;
  }

  get onboardingInProgress() {
    return this.onboardingOpen;
  }

  set onboardingInProgress(val) {
    this.onboardingOpen = val;
  }

  get isAgencyAccount() {
    return (
      this.accountDetails.profile_roles.filter((item) =>
        item.roles.includes('manager')
      ).length > 1
    );
  }

  get isManagedProfile() {
    return this.profileDetails.managing_accounts.length > 1;
  }

  /**
   * Push url to saved url stack.
   */
  set returnUrl(url) {
    if (url) {
      this.urls.push(url);
    }
  }

  get featureList() {
    let features = {};

    console.log(this.profile);

    for (let feature of this.profile?.features?.enabled_features) {
      features[feature.identifier] = true;
    }
    for (let feature of this.profile?.features?.disabled_features) {
      features[feature.identifier] = false;
    }

    return features;
  }

  isFeatureEnabled(featureIdentifier) {
    // console.log(this.profile?.features?.enabled_features);
    return (
      this.profile?.features?.enabled_features?.filter((feature) => {
        return feature.identifier === featureIdentifier;
      }).length > 0
    );
  }

  set mainBioDetails(mainBio) {
    if (mainBio) {
      this.mainBio = mainBio;
    }
  }

  get mainBioDetails() {
    return this.mainBio;
  }

  set mainPassportDetails(mainPassport) {
    if (mainPassport) {
      this.mainPassport = mainPassport;
    }
  }

  get mainPassportDetails() {
    return this.mainPassport;
  }

  set mainAboutDetails(mainAbout) {
    if (mainAbout) {
      this.mainAbout = mainAbout;
    }
  }

  get mainAboutDetails() {
    return this.mainAbout;
  }

  set mainPortfolioDetails(mainPortfolio) {
    if (mainPortfolio) {
      this.mainPortfolio = mainPortfolio;
    }
  }

  get mainPortfolioDetails() {
    return this.mainPortfolio;
  }

  /**
   * Navigates to the saved URL. If no URL saved or specified, navigates to '/'.
   * @param url default URL to use if none saved (optional)
   */
  goBack(url = undefined) {
    console.log(url);
    if (!url) {
      //Changing the logic to go to previous page
      // console.log('hello');
      this.browserLocation.back();
    }
    if (this.urls.length > 0) {
      // Use saved url if defined.
      this.router.navigateByUrl(this.urls.pop());
    } else if (url) {
      // Specify default url if nothing saved.
      this.router.navigateByUrl(url);
    } else {
      // Default: navigate to home
      this.router.navigateByUrl('/profiles/bio');
    }
  }

  status() {
    return this.http.get<any>(api.status);
  }

  /**
   * Get dictionary of query params from URL.
   * @param url URL in the general format /route?param1=value1&param2=value2&...
   * @returns dictionary of query params contained in the URL.
   */
  getQueryParamsFromUrl(url: string): object {
    let params = {};

    let query = url.split('?')[1];
    if (query) {
      for (let param of query.split('&')) {
        if (param) {
          let [key, val] = param.split('=');
          if (key && val) {
            params[key] = val;
          }
        }
      }
    }

    return params;
  }

  /**
   *
   * @param utc the date string in UTC format. ex: '2022-05-15T15:00:00'
   * @param precision the precision with which to get the local time. Defaults to 'minute'
   * @param locale the locale to use for local time. Defaults to 'en-US'
   * @param tz the timezone to use for local time. Defaults to 'Universal' aka UTC
   * @returns The readable local date and time up to the specified precision
   */
  getLocalTime(
    utc,
    precision = 'minute',
    locale = 'en-US',
    tz = 'Universal'
  ): string {
    if (!utc) {
      console.error('Empty or null date input');
      return 'Invalid Date';
    }

    let time = new Date(utc + '+00:00');
    if (isNaN(time.getTime())) {
      console.error('Invalid date:', utc);
      return 'Invalid Date';
    }

    const hasTime = utc.includes('T') && !utc.endsWith('T00:00:00');

    let options: Intl.DateTimeFormatOptions = {
      timeZone: tz,
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    };

    switch (precision) {
      case 'year':
        options = { timeZone: tz, year: 'numeric' };
        break;
      case 'month':
        options = { timeZone: tz, year: 'numeric', month: 'short' };
        break;
      case 'week':
      case 'day':
        // Keep default options
        break;
      case 'hour':
        if (hasTime) {
          options.hour = 'numeric';
        }
        break;
      case 'minute':
        if (hasTime) {
          options.hour = 'numeric';
          options.minute = 'numeric';
        }
        break;
      default:
        return '';
    }

    return new Intl.DateTimeFormat(locale, options).format(time);
  }

  // /**
  //  *
  //  * @param utc the date string in UTC format. ex: '2022-05-15T15:00:00'
  //  * @param precision the precision with which to get the local time. Defaults to 'second'
  //  * @param locale the locale to use for local time. Defaults to 'en-US'
  //  * @param tz the timezone to use for local time. Defaults to 'Universal' aka UTC
  //  * @returns The readable local date and time up to the specified precision
  //  */
  // getLocalTime(
  //   utc,
  //   precision = 'minute',
  //   locale = 'en-US',
  //   tz = 'Universal'
  // ): string {
  //   let time = new Date(utc + '+00:00');
  //   switch (precision) {
  //     case 'year':
  //       return time.toLocaleDateString(locale, {
  //         timeZone: tz,
  //         year: 'numeric',
  //       });
  //     case 'month':
  //       return time.toLocaleDateString(locale, {
  //         timeZone: tz,
  //         year: 'numeric',
  //         month: 'short',
  //       });
  //     case 'week':
  //     case 'day':
  //       return time.toLocaleDateString(locale, {
  //         timeZone: tz,
  //         year: 'numeric',
  //         month: 'short',
  //         day: 'numeric',
  //         weekday: 'short',
  //       });
  //     case 'hour':
  //       return time.toLocaleDateString(locale, {
  //         timeZone: tz,
  //         month: 'short',
  //         day: 'numeric',
  //         hour: 'numeric',
  //         weekday: 'short',
  //       });
  //     case 'minute':
  //       return time.toLocaleDateString(locale, {
  //         timeZone: tz,
  //         month: 'short',
  //         day: 'numeric',
  //         hour: 'numeric',
  //         minute: 'numeric',
  //         weekday: 'short',
  //       });
  //   }
  //   return '';
  // }

  /**
   * Create a date for a time in a specific timezone
   * @param dateTimeString A string representing the date and time in the desired timezone ("Apr 30, 2023 22:00:00")
   * @param timeZoneString A string representing the time zone ("Pacific/Honolulu")
   * @returns A Date object for the specified time in the specified timezone
   */
  getDateInTimeZone(dateTimeString: string, timeZoneString: string) {
    const utcOffset = mt.tz(dateTimeString, timeZoneString).format('Z');
    const dateStr = `${dateTimeString} UTC${utcOffset}`;
    return new Date(dateStr);
  }

  // REGEX Source -> https://gist.github.com/sethlopezme/d072b945969a3cc2cc11

  isValidColor(color: string) {
    return (
      this.isValidHex(color) ||
      this.isValidRGBA(color) ||
      this.ixValidHSLA(color)
    );
  }

  isValidHex(color: string) {
    color = color.toLowerCase().replace(/ /g, '');

    const hexMatch = /^#?[a-f\d]{6}$/;

    return hexMatch.test(color);
  }

  isValidRGBA(color: string) {
    color = color.toLowerCase().replace(/ /g, '');

    console.log(color);

    const rgbMatch =
      /^rgb\((0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d)\)$/;
    const rgbaMatch =
      /^rgba\((0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|0?\.\d|1(\.0)?)\)$/;

    return rgbMatch.test(color) || rgbaMatch.test(color);
  }

  ixValidHSLA(color: string) {
    color = color.toLowerCase().replace(/ /g, '');

    const hslMatch =
      /^hsl\((0|360|35\d|3[0-4]\d|[12]\d\d|0?\d?\d),(0|100|\d{1,2})%,(0|100|\d{1,2})%\)$/;
    const hslaMatch =
      /^hsla\((0|360|35\d|3[0-4]\d|[12]\d\d|0?\d?\d),(0|100|\d{1,2})%,(0|100|\d{1,2})%,(0?\.\d|1(\.0)?)\)$/;

    return hslMatch.test(color) || hslaMatch.test(color);
  }

  /**
   *
   * @param hex
   * @returns
   */
  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? [
          parseInt(result[1], 16),
          parseInt(result[2], 16),
          parseInt(result[3], 16),
        ]
      : null;
  }

  hexWithAlphaToRGBA(hexColor) {
    // Remove the '#' symbol if present
    hexColor = hexColor.replace('#', '');

    // Check if the hexColor has 4 or 8 characters
    if (hexColor.length !== 8) {
      throw new Error('Invalid hex color format. Expected 8 characters.');
    }

    // If the hexColor has 4 characters, duplicate each character to get the 8-character version
    if (hexColor.length === 4) {
      hexColor = hexColor
        .split('')
        .map((char) => char + char)
        .join('');
    }

    // Parse the hex values for red, green, blue, and alpha
    const red = parseInt(hexColor.substring(0, 2), 16);
    const green = parseInt(hexColor.substring(2, 4), 16);
    const blue = parseInt(hexColor.substring(4, 6), 16);
    const alpha = parseInt(hexColor.substring(6, 8), 16) / 255; // Convert alpha to decimal

    // Convert to RGBA format
    const rgbaString = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    return rgbaString;
  }

  /**
   * Calculates whether white or black text should be used given a background
   * Uses the guidelines specified by http://www.w3.org/TR/AERT#color-contrast
   * @param hexBackground Background color in hexadecimal format
   * @returns contrast text color as 'black' or 'white'
   */
  calculateContrastColorHex(hexBackground) {
    let rgb = this.hexToRgb(hexBackground);
    let textColor = '#000000';
    if (rgb) {
      const brightness = Math.round(
        (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000
      );
      textColor = brightness > 200 ? '#000000' : '#FFFFFF';
    }
    return textColor;
  }

  getDataURLFromFile(file) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  getFileFromDataURL(dataUrl) {
    var arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  _hex_to_percentage(hexString: string): number {
    const decimalValue: number = parseInt(hexString, 16); // Convert hex string to decimal
    const percentage: number = decimalValue / 255.0; // Divide by maximum decimal value (255) to get percentage
    return parseFloat(percentage.toFixed(2)); // Round the result to two decimal places
  }

  _convert_decimal_percentage_to_hex_str(decimalPercentage: number): string {
    // Convert decimal percentage to a value between 0 and 255
    const decimalValue: number = Math.round(decimalPercentage * 255);

    // Convert decimal value to hexadecimal string
    const hexadecimalString: string = decimalValue
      .toString(16)
      .toUpperCase()
      .padStart(2, '0');

    return hexadecimalString;
  }

  _rec_merge(obj1, obj2) {
    const merged = { ...obj1 };

    for (let key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        if (
          obj2[key] &&
          typeof obj2[key] === 'object' &&
          !Array.isArray(obj2[key])
        ) {
          if (
            !obj1[key] ||
            typeof obj1[key] !== 'object' ||
            Array.isArray(obj1[key])
          ) {
            merged[key] = {};
          }
          merged[key] = this._rec_merge(obj1[key], obj2[key]);
        } else {
          merged[key] = obj2[key];
        }
      }
    }

    return merged;
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Returns a different number each time it's called by incrementing
   * the uniqueCounter variable.
   */
  getUniqueId() {
    this.uniqueCounter += 1;
    return this.uniqueCounter;
  }

  /**
   * Unline normal parse float, this will throw an error if the string is
   * not in the correct format.
   */
  strictParseFloat(str) {
    // Check if the string in a valid number format
    const floatRegex = /^[-+]?[0-9]*\.?[0-9]+$/;
    if (!floatRegex.test(str)) {
      throw new Error('Invalid float format');
    }

    return parseFloat(str);
  }
}
