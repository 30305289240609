// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activated-btn {
  border-color: var(--mat-theme-accent);
}

.social-container {
  border-radius: 4px;
}

.badge-icon {
  height: 85px;
  width: 85px;
}

:host-context(.dark-mode) ::ng-deep .mat-mdc-dialog-content {
  color: white;
}
:host-context(.dark-mode) ::ng-deep .platform-option {
  color: white;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
