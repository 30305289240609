import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  inject,
  runInInjectionContext,
  Injector,
} from '@angular/core';
import { provideRouter, withPreloading, NoPreloading } from '@angular/router';
import {
  provideHttpClient,
  withInterceptorsFromDi,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { routes } from './app.routes';
import { UniversalAppInterceptor } from './interceptors/universal-app.interceptor';
import { environment } from '../environments/environment';
import { CustomErrorHandlerService } from './services/error.service';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { provideGoogleAnalytics } from '@hakimio/ngx-google-analytics';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FaIconLibrary, FaConfig } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { NgxColorsModule } from 'ngx-colors';
import { MatDialogModule } from '@angular/material/dialog';

function configureFontAwesome(library: FaIconLibrary, faConfig: FaConfig) {
  library.addIconPacks(fas, far, fab);
  faConfig.defaultPrefix = 'fas';
  faConfig.fixedWidth = true;
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withPreloading(NoPreloading)),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UniversalAppInterceptor,
      multi: true,
    },
    provideAnimations(),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    provideGoogleAnalytics(environment.ga), // ⬅️ Google Analytics provider
    FontAwesomeModule,
    {
      provide: APP_INITIALIZER,
      useFactory: (injector: Injector) => () => {
        return runInInjectionContext(injector, () => {
          const library = inject(FaIconLibrary);
          const faConfig = inject(FaConfig);
          configureFontAwesome(library, faConfig);
        });
      },
      deps: [Injector],
      multi: true,
    },
    NgxColorsModule
  ],
};
