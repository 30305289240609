import { Component, OnInit } from '@angular/core';
import { firstValueFrom, timer } from 'rxjs';
import { AuthService } from '../services/auth.service';
import {
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { takeWhile, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgClass } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatInput } from '@angular/material/input';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
  MatError,
} from '@angular/material/form-field';
import { MatButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
  standalone: true,
  imports: [
    MatRipple,
    MatButton,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    FaIconComponent,
    MatSuffix,
    MatError,
    NgClass,
  ],
})
export class AuthenticationComponent implements OnInit {
  isSignUp = false;
  step = 1;

  email: string = '';
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  canResend: boolean = true;
  resendCountdown: number = 20;

  constructor(private auth: AuthService) {}

  ngOnInit() {
    console.log(window.location.href);
    if (window.location.href.includes('sign-up')) {
      this.isSignUp = true;
    }
  }

  getEmailErrorMessage() {
    if (this.emailFormControl.hasError('required')) {
      return 'You must enter a value';
    }

    return this.emailFormControl.hasError('email') ? 'Not a valid email' : '';
  }

  async signIn(provider) {
    const { url, redirect_url } = await firstValueFrom(
      this.auth.signInProvider(provider)
    );
    console.log(url, redirect_url); //adding multiple as cognito have different field

    // localStorage.setItem('boardMode', 'guestFlow');

    // Only redirect to profiles if there isn't already a signin redirect
    // if (!localStorage.getItem('signinRedirect')) {
    //   localStorage.setItem('signinRedirect', '/profiles');
    // }

    window.open(url || redirect_url, '_self');
  }

  async sendEmail() {
    if (this.emailFormControl.valid) {
      try {
        const email = this.emailFormControl.value;
        const result = await firstValueFrom(
          this.auth.signInWithEmail(email).pipe(
            catchError((error) => {
              console.error('Error sending sign-in link:', error);
              // Handle error (e.g., show an error message to the user)
              return of(null);
            })
          )
        );
        console.log(result);
        if (this.isSignUp) {
          // Handle sign-up specific logic if needed
        }

        this.step = 3; // Move to the "Check your Email" step
        this.startResendCountdown();
      } catch (error) {
        console.error('Error processing sign-in with email:', error);
        // Handle error (e.g., show an error message to the user)
      }
    } else {
      // If the email is invalid, mark the form control as touched to show validation errors
      this.emailFormControl.markAsTouched();
    }
  }

  startResendCountdown() {
    this.canResend = false;
    this.resendCountdown = 20;
    timer(0, 1000)
      .pipe(takeWhile(() => this.resendCountdown > 0))
      .subscribe(() => {
        console.log(this.resendCountdown);
        this.resendCountdown--;
        if (this.resendCountdown === 0) {
          this.canResend = true;
        }
      });
  }

  async resendEmail() {
    if (this.canResend && this.emailFormControl.valid) {
      try {
        const email = this.emailFormControl.value;
        await firstValueFrom(
          this.auth.signInWithEmail(email).pipe(
            catchError((error) => {
              console.error('Error resending sign-in link:', error);
              // Handle error (e.g., show an error message to the user)
              return of(null);
            })
          )
        );
        this.startResendCountdown();
      } catch (error) {
        console.error('Error processing resend email:', error);
        // Handle error (e.g., show an error message to the user)
      }
    }
  }

  async signUp(provider) {
    const { url, redirect_url } = await firstValueFrom(
      this.auth.signUp(provider)
    );
    console.log(url, redirect_url);

    // Don't think this is needed, as the api callback navigates to /pages by default now.

    // Only redirect to profiles if there isn't already a signin redirect
    // if (!localStorage.getItem('signinRedirect')) {
    //   localStorage.setItem('signinRedirect', '/profiles');
    // }

    // localStorage.setItem('boardMode', 'guestFlow');
    // localStorage.setItem('signinRedirect', this.router.url);
    window.open(url || redirect_url, '_self');
  }
}
