// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.identity-banner {
  background-color: var(--primary);
  padding: 10px;
  border-bottom: 4px solid var(--primary);
}

.identity-banner-inner {
  border-color: #FFFFFF;
  border-width: 2px;
  border-radius: 8px 8px 0px 0px;
  padding: 10px;
}

.identity-contents {
  background-color: var(--mat-app-background-color);
  padding: 20px;
}
@media (max-width: 500px) {
  .identity-contents {
    padding: 10px;
  }
}

.info-card {
  background-color: var(--mat-app-background-color);
  border-radius: 40px;
  padding: 25px;
  width: 95%;
}
@media (max-width: 500px) {
  .info-card {
    padding: 15px;
  }
}

.identity-tooltip {
  position: absolute;
  bottom: 0;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 500px;
}

.req-image {
  width: 60px;
}

.flow-img {
  height: 250px;
}
@media (max-width: 500px) {
  .flow-img {
    height: 150px;
  }
}

.preview-img {
  height: 300px;
}
@media (max-width: 500px) {
  .preview-img {
    height: 200px;
  }
}

.badge-icon {
  height: 85px;
  width: 85px;
}

.identity-body {
  font-size: 10pt;
  font-weight: 400;
  color: #666;
  text-align: center;
}
@media (max-width: 500px) {
  .identity-body {
    font-size: 9pt;
  }
}

.identity-title {
  font-size: 14pt;
  font-weight: 400;
  text-align: center;
}

.requirement-box {
  padding: 5px;
  border: 2px solid var(--primary);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
}
.requirement-box.icon {
  height: 100px;
  width: 100px;
}
.requirement-box.text {
  width: 100px;
  height: 50px;
  font-size: 10px;
  line-height: 13px;
}

.requirement-carousel {
  max-width: 360px;
}

.identity-outline {
  padding: 10px;
  border: 2px solid var(--primary);
  border-radius: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
