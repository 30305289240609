import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { AccountService } from '../services/account.service';
import { AuthService } from '../services/auth.service';
import { JWTTokenService } from '../services/jwttoken.service';
import { KickStartService } from '../services/kick-start.service';
import { LocalStorageService } from '../services/local-storage.service';
import { PledgeService } from '../services/pledge.service';
import { ProductsService } from '../services/products.service';
import { ProfilesService } from '../services/profiles.service';
import { ReferralService } from '../services/referral.service';
import { UtilitiesService } from '../services/utilities.service';
import { OnboardPage } from '../onboard/onboard.page';
import { BadgesComponent } from '../badges/badges.component';
import { SocialLabelComponent } from '../account/social-label/social-label.component';
import { PromoService } from '../services/promo.service';
import { ThemesService } from '../services/themes.service';
import { OrdersService } from '../services/orders.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { DomainService } from '../services/domain.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { PageService } from '../services/page.service';
import { SandboxService } from '../services/sandbox.service';

/**
 * Provides access management for an arbitrary page based on tokens and other options.
 * Default behavior requires a valid anonymous token only.
 *
 * Additional options can be provided in `guardOptions` parameter of the route data.
 * @example
 * {
 *   path: 'my-component',
 *   component: MyComponent,
 *   canActivate: [AuthGuard],
 *   data: {
 *     guardOptions: {
 *       requireToken: true,
 *       requireAuthenticatedToken: true,
 *       resumeOnboarding: true,
 *       refreshToken: true,
 *       refreshTokenAsync: true,
 *       updateAccountInfo: true,
 *       updateAccountInfoAsync: false,
 *       updateProfileInfo: true,
 *       updateProfileInfoAsync: false,
 *     }
 *   }
 * }
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private storage: LocalStorageService,
    private auth: AuthService,
    private router: Router,
    private jwt: JWTTokenService,
    private account: AccountService,
    private pledgeService: PledgeService,
    private kickStartService: KickStartService,
    private profiles: ProfilesService,
    private products: ProductsService,
    private utilities: UtilitiesService,
    private domainService: DomainService,
    private referralService: ReferralService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private promoService: PromoService,
    private themes: ThemesService,
    private orderService: OrdersService,
    private pageService: PageService,
    private sandboxService: SandboxService
  ) {}

  /**
   * Opens the onboarding dialog with the given step and mode
   * @param step - next onboarding step (string)
   * @param mode - onboarding mode (string)
   * @returns afterClosed dialog promise
   */
  public onboardPrompt(step: string, mode: string): Promise<boolean> {
    return firstValueFrom(
      this.dialog
        .open(OnboardPage, {
          autoFocus: step === 'text-verification' ? false : true,
          disableClose: true,
          panelClass: `onboard-${mode ? 'dialog-' + mode : 'dialog'}`,
          data: {
            nextStep: step,
            mode,
          },
        })
        .afterClosed()
    );
  }

  /**
   * Opens the Badge Dialog process in the specified mode
   * at the specified page.
   * @returns The reference to the opened dialog.
   */
  public verifyOAuthPrompt(
    mode: string,
    page: string,
    provider: string = null,
    success: boolean = null,
    linkAdded: boolean = null
  ) {
    const dialogRef = this.dialog.open(BadgesComponent, {
      data: {
        mode,
        page,
        oauth: {
          provider,
          success,
          linkAdded,
        },
      },
      panelClass:
        page === 'identity' ? 'custom-identity-dialog' : 'custom-badge-dialog',
    });

    return dialogRef;
  }

  /**
   * Handles all token and localstorage management needed before granting access to a route.
   * @param route[ActivatedRouteSnapshot]
   * @param state[RouterStateSnapshot]
   * @returns boolean representing if access to the route should be allowed.
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    //to bypass login
    // return true;

    //For calculating performance
    const startTime = performance.now(); // Start timing

    /* Options */
    const options = {
      // Default
      requireToken: true,
      requireAuthenticatedToken: false,
      requireAnonymousToken: false,
      resumeOnboarding: false,
      resumeOnboardingAsync: false,
      refreshToken: false,
      refreshTokenAsync: false,
      updateAccountInfo: false,
      updateAccountInfoAsync: false,
      updateProfileInfo: false,
      updateProfileInfoAsync: false,
      updateActiveProductInfo: false,
      updateActiveProductInfoAsync: false,
      updateSvDomains: false,
      updateSvDomainsAsync: false,
      ignoreSigninRedirect: false,
      consumeSigninRedirect: true,
      discardReferralId: false,
      sandbox: false,
      // Override if specified in route data
      ...route.data.guardOptions,
    };
    const {
      requireToken,
      requireAuthenticatedToken,
      requireAnonymousToken,
      resumeOnboarding,
      resumeOnboardingAsync,
      refreshToken,
      refreshTokenAsync,
      updateAccountInfo,
      updateAccountInfoAsync,
      updateProfileInfo,
      updateProfileInfoAsync,
      updateActiveProductInfo,
      updateActiveProductInfoAsync,
      updateSvDomains,
      updateSvDomainsAsync,
      ignoreSigninRedirect,
      consumeSigninRedirect,
      discardReferralId,
      sandbox,
    } = options;

    /* URL parameters */
    const urlToken = route.queryParams.token;
    const urlReferralId = route.paramMap.get('referralId');

    /* LocalStorage parameters */
    const {
      token: localToken,
      referralId: localReferralId,
      referralPageId: localReferralPageId,
      sandboxSessionId: localSandboxSessionId,
      // sandboxTheme: localSandboxTheme,
      username: localUsername,
      pledge: localPledgeData,
      kickStart: localKickstartData,
      usernamePromoCode: localUsernamePromoCode,
      signinRedirect: localSigninRedirect,
      boardMode: localOnboardMode,
      oauthBadgeMode: localOAuthBadgeMode,
      oauthProvider: localOAuthProvider,
      oauthSuccess: localOAuthSuccess,
      oauthLinkAdded: localOAuthLinkAdded,
      promoCode: localPromoCode,
      preferredDomain: localPreferredDomain,
      identityBadgeAutomaticRedirect: identityBadgeAutomaticRedirect,
      customThemeActive: localCustomThemeActive,
      selectedProfileId: localSelectedProfileId,
      onboardingCompleted: localOnboardingCompleted,
    } = localStorage;

    /* Determine status of current token */
    const token = urlToken || localToken; // URL token takes priority
    this.jwt.setToken(token);
    this.jwt.decodeToken();
    const expired = this.jwt.isTokenExpired();
    const authenticated = this.jwt.isAuthenticatedUser();
    const oauthCompleted =
      localOAuthBadgeMode &&
      localOAuthProvider &&
      localOAuthSuccess &&
      localOAuthLinkAdded;

    // this.router.navigateByUrl('/~cCI4Sx');

    console.log(
      { token },
      expired,
      authenticated,
      requireToken,
      requireAuthenticatedToken,
      state.url
    );
    // return;
    if (!authenticated && state.url.includes('edit')) {
      const url = state.url.replace('/edit', '');
      console.log(url);
      this.router.navigateByUrl(url);
      return false;
    }

    /* Redirect to specified route */
    if (authenticated && localSigninRedirect && !ignoreSigninRedirect) {
      if (consumeSigninRedirect) {
        this.storage.remove('signinRedirect');
      }
      if (state.url !== localSigninRedirect) {
        this.router.navigateByUrl(localSigninRedirect);
        return false;
      }
    }

    /* Token required */
    if (!token && requireToken && !requireAuthenticatedToken) {
      await this.refreshToken();
    }

    /* Authenticated Token Required */
    if (!authenticated && requireAuthenticatedToken) {
      this.jwt.removeToken();
      this.authenticate(localSigninRedirect || state.url);
      return false;
    }

    if (token && expired) {
      this.jwt.removeToken();
      if (requireAuthenticatedToken) {
        this.authenticate(localSigninRedirect || state.url);
        return false;
      } else {
        await this.refreshToken();
      }
    }

    /* Only available to anonymous users */
    if (authenticated && requireAnonymousToken) {
      this.router.navigateByUrl('/profiles');
      return false;
    }

    /* Refresh token */
    if (refreshToken) {
      if (refreshTokenAsync) {
        this.refreshToken();
      } else {
        await this.refreshToken();
      }
    }

    /* Save Sandbox Session */
    if (authenticated) {
      // Moving this before the account/profile fetch since claiming
      // the sandbox can alter a user's memberships.
      await this.saveSandboxData(localSandboxSessionId, sandbox);
    }

    /* Populate Account and Profile Details */
    if (authenticated) {
      //TODO commenting account retrieve as its part of Profile retrieve
      if (updateAccountInfo || oauthCompleted) {
        // Badge flows will require the account and
        // profile in the utilities service to be
        // up to date.
        if (updateAccountInfoAsync && !oauthCompleted) {
          this.updateAccountDetails();
        } else {
          await this.updateAccountDetails();
        }
      }
      if (updateProfileInfo || oauthCompleted) {
        if (updateProfileInfoAsync && !oauthCompleted) {
          this.updateProfileDetails(localSelectedProfileId);
        } else {
          await this.updateProfileDetails(localSelectedProfileId);
        }
      }
      if (updateActiveProductInfo || oauthCompleted) {
        if (updateActiveProductInfoAsync && !oauthCompleted) {
          this.updateActiveProduct();
        } else {
          await this.updateActiveProduct();
        }
      }
    }

    /* Populate SV Domains */
    if (updateSvDomains) {
      if (updateSvDomainsAsync) {
        this.updateSvDomains();
      } else {
        await this.updateSvDomains();
      }
    }

    /* Handle post-authentication SYNC updates */
    if (authenticated) {
      await this.redeemPromoCode(localPromoCode);
      await this.updateUsername(localUsername, localUsernamePromoCode);
      await this.handlePledge(localPledgeData);
      await this.handleKickStart(localKickstartData);
      await this.updatePreferredDomain(localPreferredDomain);

      // Changing to be SYNC so it doesn't open onboarding before going to the new account page.
      const pageSwitched = await this.recordReferralEvent(
        localReferralId,
        localReferralPageId,
        discardReferralId,
        state.url,
        updateAccountInfo
      );

      // If a new account was detected and the page was changed, abort this auth guard call.
      if (pageSwitched) {
        return false;
      }
    }

    /* Handle post-authentication ASYNC updates */
    // if (authenticated) {

    // }

    /* Open Onboarding Dialogs */
    if (resumeOnboarding && localOnboardingCompleted !== 'true') {
      if (!this.utilities.accountDetails._id) {
        if (resumeOnboardingAsync) {
          this.updateAccountDetails().then(() => {
            this.resumeOnboarding(
              authenticated,
              resumeOnboarding,
              oauthCompleted,
              localOAuthBadgeMode,
              localOAuthProvider,
              localOAuthSuccess,
              localOAuthLinkAdded,
              localOnboardMode,
              identityBadgeAutomaticRedirect
            );
          });
        } else {
          await this.updateAccountDetails();
          this.resumeOnboarding(
            authenticated,
            resumeOnboarding,
            oauthCompleted,
            localOAuthBadgeMode,
            localOAuthProvider,
            localOAuthSuccess,
            localOAuthLinkAdded,
            localOnboardMode,
            identityBadgeAutomaticRedirect
          );
        }
      } else {
        this.resumeOnboarding(
          authenticated,
          resumeOnboarding,
          oauthCompleted,
          localOAuthBadgeMode,
          localOAuthProvider,
          localOAuthSuccess,
          localOAuthLinkAdded,
          localOnboardMode,
          identityBadgeAutomaticRedirect
        );
      }
    } else if (localOnboardingCompleted === 'true') {
      localStorage.removeItem('onboardingCompleted');
    }

    // Uncomment this to test onboarding // TODO Guest Flow
    // this.onboardPrompt('start', 'full');
    // this.onboardPrompt('account-settings', 'full');
    // this.onboardPrompt('account-verification', 'full');
    // this.onboardPrompt('blue-ribbon', 'full');
    // this.onboardPrompt('badges-verification', 'full');
    // this.onboardPrompt('public-profile-information', 'full');
    // this.onboardPrompt('choose-profile-picture', 'full');
    // this.onboardPrompt('username', 'full');
    // this.onboardPrompt('account-created', 'full');

    // this.onboardPrompt('guest-preview', 'guestFlow');

    // this.onboardPrompt('account-settings', 'guestFlow');

    // this.onboardPrompt('account-settings', 'creator');
    // this.onboardPrompt('expand-reach', 'creator');
    // this.onboardPrompt('creator-pricing', 'creator');
    // this.onboardPrompt('username', 'creator');
    // this.onboardPrompt('account-created', 'creator');

    // /* Open Badge Modal */
    // const dialogRef = this.verifyOAuthPrompt(
    //   localOAuthBadgeMode,
    //   'verify-social-media',
    //   localOAuthProvider,
    //   localOAuthSuccess,
    //   localOAuthLinkAdded
    // );

    // //for testing TODO
    // this.dialog.open(SocialLabelComponent, {
    //   // disableClose: true,
    //   panelClass: 'onboard-dialog',
    // });

    // //for badges
    // const dialogRef = this.dialog.open(BadgesComponent, {
    //   data: {
    //     mode: 'standard',
    //     page: 'qr-account-verification',
    //   },
    //   panelClass: 'custom-badge-dialog',
    // });
    const endTime = performance.now(); // End timing
    console.log(
      `Guard execution time: ${(endTime - startTime) / 1000} seconds`
    ); // Log the execution time

    return true;
  }

  /**
   * Performs a refresh of the token stored in local storage. If no token is
   * found, a fresh anonymous one will be generated.
   */
  async refreshToken() {
    const res = await firstValueFrom(this.auth.signIn());
    this.auth.initiateJwt(res.token);
  }

  /**
   * Fetches the cognito signin url and opens it in the same window.
   */
  async authenticate(callback_url = null) {
    this.storage.set('signinRedirect', callback_url);
    this.auth.navigateToAuthPage();
  }

  /**
   * Fetches the account details based on the user id in the jwt token,
   * and stores them in the utilities service.
   */
  async updateAccountDetails() {
    const accountResponse = await firstValueFrom(
      this.account.retrieveAccount(this.jwt.getSub())
    );

    console.log({ accountResponse });

    this.utilities.accountDetails = accountResponse;
  }

  /**
   * Fetches the bio profile details from the bioProfileId in the utilities
   * service, and stores them in the utilities service.
   */
  async updateProfileDetails(profileId) {
    await this.profiles.retrieveProfile(profileId);
  }

  /**
   * Fetches the membership details from the customerId in the utilities
   * service, and stores them in the utilities service.
   */
  async updateActiveProduct() {
    console.log('updating active product');
    try {
      const productsResponse = await firstValueFrom(
        this.products.listProducts()
      );
      console.log('Products Response:');
      console.log(productsResponse);
      this.utilities.activeProduct = productsResponse.results.find(
        (element) => element.is_active_for_bio == true
      );
      console.log(this.utilities.activeProduct);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Fetches all valid SV domains and stores them in the utilities service.
   */
  async updateSvDomains() {
    const res = await this.domainService.listDomains();
    console.log(res);
  }

  /**
   * Checks if the pledge has been properly recorded. If so, removes it from
   * localstorage.
   * @param guest guest info as stored in localstorage
   */
  handleGuestFlow() {
    this.onboardPrompt('guest-dob', 'guestFlow');
    // if (guest) {
    // this.pledgeService.createPledge(JSON.parse(pledge)).subscribe({
    //   next: (data) => {
    //     if (data._id) {
    //       localStorage.removeItem('pledge');
    //     }
    //   },
    //   error: ({ error }) => {
    //     if (error.error_code === 'already_exists') {
    //       localStorage.removeItem('pledge');
    //     }
    //   },
    // });
    // }
  }

  /**
   * Checks if the pledge has been properly recorded. If so, removes it from
   * localstorage.
   * @param pledge Pledge info as stored in localstorage
   */
  async handlePledge(pledge) {
    if (pledge) {
      const result = await firstValueFrom(
        this.pledgeService.createPledge(JSON.parse(pledge))
      );
      if (
        result &&
        result.error &&
        result.error.error_code === 'already_exists'
      ) {
        localStorage.removeItem('pledge');
      } else if (result && result._id) {
        localStorage.removeItem('pledge');
      }
    }
  }

  /**
   * Records the
   * localstorage.
   * @param kickStart kickStart promo info as stored in localstorage
   */
  async handleKickStart(kickStart) {
    if (kickStart) {
      const result = await firstValueFrom(
        this.kickStartService.createKickStart(JSON.parse(kickStart))
      );
      if (
        result &&
        result.error &&
        result.error.error_code === 'already_exists'
      ) {
        localStorage.removeItem('kickStart');
      } else if (result && result._id) {
        localStorage.removeItem('kickStart');
      }
    }
  }

  /**
   * Records any relevant referral events based on the signed in
   * account and the provided referral Id.
   * @param referralId
   * @returns True or false based on if the page has been switched
   * to the signup confirmation page
   */
  async recordReferralEvent(
    referralId,
    referralPageId,
    discardReferralId = false,
    currentUrl,
    updateAccountInfo = false
  ) {
    // Changing logic to not load account info if it's a public page
    if (updateAccountInfo) {
      // If account has not been fetched yet, fetch it.
      if (!this.utilities.accountId) {
        await this.updateAccountDetails();
      }
    }

    const new_account = this.utilities.accountDetails.new_account;

    // Update Account
    if ((referralId && !discardReferralId) || new_account) {
      const response: any = await firstValueFrom(
        this.account.updateAccount(
          this.utilities.accountId,
          Object.assign(
            {},
            new_account ? { new_account: false } : null,
            referralId && !discardReferralId
              ? {
                  referred_by_data: {
                    referral_id: referralId,
                    page_id: referralPageId,
                  },
                }
              : null
          )
        )
      );
      this.utilities.accountDetails = response;
    }

    // Drop referral id
    if (discardReferralId || referralId) {
      this.storage.remove('referralId');
      this.storage.remove('referralPageId');
    }

    // Navigate to signup confirmation page
    if (new_account) {
      // Get user active plan
      const result = await firstValueFrom(this.orderService.listOrders());
      const nickname =
        result.results.length > 0
          ? result.results[0].transaction_info.price.nickname
          : 'None';

      // Go to new account welcome page
      this.storage.set('signinRedirect', currentUrl);
      this.router.navigateByUrl(
        `/signup/complete?membership=${nickname.replace(' ', '_')}`
      );

      return true;
    }

    return false;
  }

  /**
   * Checks if the user has completed onboarding and sends them to the next step if not.
   * @returns Boolean representing whether the user has been redirected to onboarding or not.
   */
  resumeOnboardingFromLastStep(boardMode = 'full') {
    //logic to redirect to onboarding page
    const {
      onboarding_completed: onboardingCompleted,
      onboarding_next_step: onboardingNextStep,
    } = this.utilities.accountDetails;

    if (!onboardingCompleted && !this.utilities.onboardingInProgress) {
      this.utilities.onboardingInProgress = true;
      return this.onboardPrompt(onboardingNextStep, boardMode).then((data) => {
        this.utilities.onboardingInProgress = false;
      });
    }
  }

  /**
   * Updates the user's profiles based on the sandbox data created
   * prior to signin.
   * @param sandboxData
   */
  async saveSandboxData(sandboxSessionId, sandbox) {
    if (sandbox || !sandboxSessionId) return;

    localStorage.removeItem('sandboxSessionId');

    const sandboxSession = await firstValueFrom(
      this.sandboxService.updateSandboxSession(sandboxSessionId, {})
    );

    // For signups, need to disable the old main page first.
    // if (this.utilities.accountDetails.new_account) {
    //   const mainPage = await this.pageService.retrieveMainPage(
    //     sandboxSession.page_type
    //   );

    //   if (mainPage) {
    //     await this.pageService.updatePage(
    //       mainPage._id,
    //       sandboxSession.page_type,
    //       {
    //         is_main: false,
    //       },
    //       undefined,
    //       undefined,
    //       { minimized_fields: 'all' }
    //     );
    //   }
    // }

    // Update sandbox page to link it to this account.
    // await this.pageService.updatePage(
    //   sandboxSession.page_id,
    //   sandboxSession.page_type,
    //   {
    //     is_main: this.utilities.accountDetails.new_account,
    //   },
    //   undefined,
    //   undefined,
    //   { minimized_fields: 'all' }
    // );
  }

  /**
   * Updates the user's bio profile with the specified username,
   * including the promo code if available.
   *
   * Opens a snackbar to inform the user of success or failure.
   * @param username
   * @param code
   */
  async updateUsername(username = null, code = null) {
    if (username && username.trim().length > 0) {
      try {
        var data: any = await firstValueFrom(
          this.profiles.updateProfile(
            this.utilities.profileId,
            { username: username },
            code
          )
        );
      } catch (e) {
        console.log(e);
      }

      console.log({ data });

      if (data && data._id) {
        this.snackBar.open(`Username ${username} claimed`, 'OK', {
          duration: 3000,
        });
        this.storage.remove('username');
        this.storage.remove('usernamePromoCode');
      } else if (code) {
        console.log('Failed to claim username with code');
        this.storage.remove('usernamePromoCode');
      } else {
        console.log('Failed to claim username without code');
      }
    }
  }

  /**
   * Redeems a promo code, granting the profile any benefits that it provides.
   * @param code
   */
  async redeemPromoCode(code) {
    if (code) {
      const data = await firstValueFrom(
        this.promoService.redeemCode({ code, membership: 'vip' })
      );
      console.log({ data });
      this.storage.remove('promoCode');
    }
  }

  // /**
  //  * Updates the user's bio profile with the specified username,
  //  * including the promo code if available.
  //  *
  //  * Opens a snackbar to inform the user of success or failure.
  //  * @param domain
  //  */
  // async updatePreferredDomain(domain = null) {
  //   if (domain) {
  //     for (let pageType in ['bio', 'passport', 'about']) {
  //       try {
  //         // Get main page
  //         const page = await this.pageService.retrieveMainPage(
  //           pageType,
  //           true,
  //           'all'
  //         );
  //         if (page) {
  //           // Update main page
  //           var data: any = await this.pageService.updatePage(page._id, {
  //             preferred_domain: domain,
  //           });
  //         }
  //       } catch (e) {
  //         console.log(e);
  //       }

  //       if (data && data._id) {
  //         this.storage.remove('preferredDomain');
  //       } else {
  //         console.log(`Failed to update domain for ${pageType}`);
  //       }
  //     }
  //   }
  // }

  // updating this function for the creator invite to work with updated logic
  async updatePreferredDomain(domain: string | null = null): Promise<void> {
    if (!domain) {
      console.log('No domain provided, skipping update');
      return;
    }

    const pageTypes = ['bio', 'passport', 'about'];

    for (const pageType of pageTypes) {
      try {
        const page = await this.pageService.retrieveMainPage(
          pageType,
          true,
          'all'
        );

        if (!page) {
          console.log(`No main page found for ${pageType}`);
          continue;
        }

        console.log(page);
        console.log(domain);

        const updatedPage = await this.pageService.updatePage(
          page._id,
          pageType,
          {
            preferred_domain: domain,
          }
        );

        if (updatedPage && updatedPage._id) {
          console.log(`Successfully updated domain for ${pageType}`);
          this.storage.remove('preferredDomain');
        } else {
          console.log(`Failed to update domain for ${pageType}`);
        }
      } catch (error) {
        console.error(`Error updating domain for ${pageType}:`, error);
      }
    }
  }

  /**
   * Uploads a custom theme
   * @param body The body of the theme POST
   * @param photo the background photo file (if needed)
   */
  async createTheme(body, photo = null) {
    const response: any = await firstValueFrom(this.themes.createTheme(body));
    console.log(response);

    //for background upload
    if (photo && response.image_upload_url) {
      console.log('UPLOADING IMAGE');
      const uploadResponse = await this.uploadThemeBackground(
        response.image_upload_url,
        photo
      );
      if (!uploadResponse) {
        console.log('Image Upload Failed');
      }
    }

    this.snackBar.open('Theme Saved', 'Ok', {
      duration: 3000,
    });

    return response.theme ? response.theme : response;
  }

  /**
   * Uploads a theme background file to S3
   * @param urlData the URL data provided by the previous Themes POST
   * @param photo The photo file
   */
  async uploadThemeBackground(urlData, photo) {
    //new code to fix image upload in chrome
    // const res = await fetch(this.newPhoto);
    const ext = photo.type;
    const file = photo;
    // you have raw data and content-type

    console.log(ext, file);

    // // Get presigned image upload URL
    // const presigned_response: any = await firstValueFrom(
    //   this.profilesService.imageUploadUrl(this.profileId, {
    //     extension: ext,
    //   })
    // );
    console.log(urlData);

    // return;

    const {
      url,
      fields: {
        AWSAccessKeyId,
        key,
        policy,
        signature,
        'x-amz-security-token': token,
      },
    } = urlData;

    // Format formdata
    const formData: FormData = new FormData();
    formData.append('AWSAccessKeyId', AWSAccessKeyId);
    formData.append('key', key);
    formData.append('policy', policy);
    formData.append('signature', signature);
    formData.append('x-amz-security-token', token);
    formData.append('file', file);

    // Upload formdata to presigned URL
    const uploadResponse: any = await firstValueFrom(
      this.profiles.imageUpload(url, formData)
    );

    // Return file index and extension
    return {
      index: url,
      ext,
    };
  }

  resumeOnboarding(
    authenticated,
    resumeOnboarding,
    oauthCompleted,
    localOAuthBadgeMode,
    localOAuthProvider,
    localOAuthSuccess,
    localOAuthLinkAdded,
    localOnboardMode,
    identityBadgeAutomaticRedirect
  ) {
    // For new accounts, it should go to the welcome page first
    if (!this.utilities.accountDetails.new_account) {
      if (oauthCompleted) {
        /* Open Badge Modal */
        const dialogRef = this.verifyOAuthPrompt(
          localOAuthBadgeMode,
          'verify-social-media',
          localOAuthProvider,
          localOAuthSuccess,
          localOAuthLinkAdded
        );

        /* Consume oauth data */
        localStorage.removeItem('oauthBadgeMode');
        localStorage.removeItem('oauthProvider');
        localStorage.removeItem('oauthSuccess');
        localStorage.removeItem('oauthLinkAdded');

        if (authenticated && resumeOnboarding) {
          /* Make sure the badge dialog finishes before opening the onboard dialog */
          dialogRef.afterClosed().subscribe(() => {
            /* Resume onboarding if signed in */
            const dialogClosed =
              this.resumeOnboardingFromLastStep(localOnboardMode);
          });
        }
      } else if (
        authenticated &&
        identityBadgeAutomaticRedirect &&
        this.profiles.isFeatureEnabledForProfile('identity-badge')
      ) {
        this.storage.remove('identityBadgeAutomaticRedirect');

        const dialogRef = this.verifyOAuthPrompt('normal', 'identity');

        if (authenticated && resumeOnboarding) {
          /* Make sure the badge dialog finishes before opening the onboard dialog */
          dialogRef.afterClosed().subscribe(() => {
            /* Resume onboarding if signed in */
            const dialogClosed =
              this.resumeOnboardingFromLastStep(localOnboardMode);
          });
        }
      } else if (authenticated && resumeOnboarding) {
        /* Resume onboarding if signed in */
        const dialogClosed =
          this.resumeOnboardingFromLastStep(localOnboardMode);
      }
    }
  }
}
